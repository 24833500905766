<script setup lang="ts">
    import { useDisplay } from "vuetify";
    import { onMounted, ref } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import useVuelidate from "@vuelidate/core";
    import { getErrors, required } from "@/composables/dutchVuelidate";
    import authenticationService from "@/services/AuthenticationService";
    import { VAlert } from "vuetify/components";
    import axios from "axios";
    import { useInstanceStore } from "@/store/instance";

    const { smAndDown } = useDisplay();
    const router = useRouter();
    const route = useRoute();
    const instanceStore = useInstanceStore();
    const notification = ref<{
        type: VAlert["type"];
        message: string;
    }>({
        type: undefined,
        message: "",
    });
    const notificationActive = ref(false);

    onMounted(() => {
        if (!instanceStore.apiUrl || !instanceStore.name) {
            toSelectInstance();
            return;
        }

        if (route.query.registered) {
            notificationActive.value = true;
            notification.value = {
                type: "success",
                message:
                    "Je bent successvol geregistreerd! Wacht tot een beheerder je account heeft goedgekeurd.",
            };
        }
        if (route.query.resetPassword) {
            notificationActive.value = true;
            notification.value = {
                type: "success",
                message: "Je wachtwoord is aangepast.",
            };
        }
    });

    const rules = {
        username: {
            required,
        },
        password: {
            required,
        },
    };

    const username = ref();
    const password = ref();

    const loading = ref();

    const showPassword = ref(false);

    const v$ = useVuelidate(rules, {
        username,
        password,
    });

    const submit = async () => {
        // Do some validation
        loading.value = true;
        notificationActive.value = false;
        v$.value.$touch();
        if (v$.value.$invalid) {
            loading.value = false;
            return;
        }

        // Submit
        try {
            await authenticationService.login(username.value, password.value);

            if (route.query.redirect) {
                await router.replace(route.query.redirect as string);
            } else {
                await router.replace("/");
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const errorCode = e?.response?.data.error_code;
                notificationActive.value = true;

                switch (errorCode) {
                    case -1203:
                        notification.value = {
                            type: "error",
                            message:
                                "Je account is nog niet goedgekeurd door een beheerder.",
                        };
                        break;
                    case -1201:
                        notification.value = {
                            type: "error",
                            message:
                                "Je gebruikersnaam of wachtwoord klopt niet.",
                        };
                        break;
                    case -1205:
                        notification.value = {
                            type: "error",
                            message:
                                "Je naam komt overeen met meerdere accounts. Gebruik je emailadres om in te loggen.",
                        };
                        break;
                    default:
                        notification.value = {
                            type: "error",
                            message:
                                "Er is iets misgegaan tijdens het inloggen. Probeer het later opnieuw.",
                        };
                        break;
                }
            }
        } finally {
            loading.value = false;
        }
    };

    const toSelectInstance = () => {
        instanceStore.apiUrl = undefined;
        instanceStore.name = undefined;
        router.push({ name: "selectInstance" });
        return;
    };
</script>

<template>
    <v-expand-transition>
        <v-alert
            v-show="notificationActive"
            class="mb-5"
            theme="light"
            data-cy="alert"
            :type="notification.type"
            :max-width="smAndDown ? undefined : 500"
        >
            {{ notification.message }}
        </v-alert>
    </v-expand-transition>
    <v-card class="rounded-lg" :width="smAndDown ? undefined : 500">
        <v-carousel height="200px" :show-arrows="false" :hide-delimiters="true">
            <v-carousel-item cover src="https://loremflickr.com/1000/400/cat" />
        </v-carousel>
        <v-card-title>Inloggen ({{ instanceStore.name }})</v-card-title>
        <v-card-text>
            <router-link :to="{ name: 'selectInstance', query: route.query }"
                ><v-icon icon="mdi-arrow-left" />Andere actie
                kiezen</router-link
            >
            <v-form @keyup.enter="submit">
                <div class="text-subtitle-1 text-medium-emphasis">
                    Naam of email
                </div>
                <v-text-field
                    v-model="username"
                    density="compact"
                    placeholder="E-mailadres"
                    variant="outlined"
                    type="email"
                    hide-details="auto"
                    :error-messages="getErrors(v$.username.$errors)"
                    data-cy="username"
                    @blur="v$.username.$touch"
                />
                <br />
                <div class="text-subtitle-1 text-medium-emphasis">
                    Wachtwoord
                </div>
                <v-text-field
                    v-model="password"
                    placeholder="Wachtwoord"
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    :type="showPassword ? 'text' : 'password'"
                    :error-messages="getErrors(v$.password.$errors)"
                    data-cy="password"
                    @blur="v$.password.$touch"
                >
                    <template #append-inner>
                        <v-icon @click="showPassword = !showPassword">
                            {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                        </v-icon>
                    </template>
                </v-text-field>
                <br />
            </v-form>
            <v-row>
                <v-col>
                    <v-btn
                        color="primary"
                        variant="tonal"
                        @click="router.push({ name: 'register' })"
                        >Registreren
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col class="flex-grow-0">
                    <v-btn
                        color="primary"
                        variant="flat"
                        :loading="loading"
                        @click="submit"
                        >Inloggen
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="flex-row-reverse">
                <v-btn
                    color="primary"
                    variant="plain"
                    size="small"
                    @click="router.push({ name: 'requestPasswordReset' })"
                    >Wachtwoord vergeten
                </v-btn>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
