import loginPage from "@/views/authentication/LoginPage.vue";
import apiService from "@/services/ApiService";
import { RouteRecordRaw } from "vue-router";
import registerPage from "@/views/authentication/RegisterPage.vue";
import requestResetPage from "@/views/authentication/RequestPasswordResetPage.vue";
import resetPage from "@/views/authentication/ResetPasswordPage.vue";
import authenticationLayout from "@/layouts/AuthenticationLayout.vue";
import { useResetStore } from "@/composables/useResetStore";
import SelectActionPage from "@/views/authentication/SelectInstancePage.vue";

export const loginRoutes: RouteRecordRaw[] = [
    {
        path: "/",
        component: authenticationLayout,
        children: [
            {
                path: "/selectInstance",
                component: SelectActionPage,
                name: "selectInstance",
                meta: {
                    requiresAuth: false,
                    onProd: true,
                },
            },
            {
                path: "/login",
                component: loginPage,
                name: "login",
                meta: {
                    requiresAuth: false,
                    onProd: true,
                },
            },
            {
                path: "/register",
                component: registerPage,
                name: "register",
                meta: {
                    requiresAuth: false,
                    onProd: true,
                },
            },
            {
                path: "/request-password-reset",
                component: requestResetPage,
                name: "requestPasswordReset",
                meta: {
                    requiresAuth: false,
                    onProd: true,
                },
            },
            {
                path: "/reset-password",
                component: resetPage,
                name: "resetPassword",
                meta: {
                    requiresAuth: false,
                    onProd: true,
                },
            },
        ],
    },
    {
        path: "/logout",
        name: "logout",
        redirect: (to) => {
            // Invalidate the token with the server
            apiService.get("/api/v1/auth/logout").finally(() => {
                // Logout the user by resetting all state
                const resetStore = useResetStore();
                resetStore.all();
            });

            return {
                name: "selectInstance",
                query: { redirect: to.query.redirect },
            };
        },
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
];
